import moment from "moment";

export const DATE_FORMAT = "YYYY-MM-DD";
export const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";

export function formatString(input) {
    if (input === "" || input === undefined || input === null) {
        return "-";
    } else {
        return input;
    }
}

export function formatIndex(input) {
    return `${input + 1}.`;
}

export function formatSpaceAndToLower(input) {
    return input?.replace(/\s+/g, "-")?.toLowerCase();
}

export function formatDateTime(input) {
    if (input === "" || input === undefined || input === null) {
        return "-";
    } else if (moment(input).format("YYYY-MM-DD HH:mm:ss") <= "1900-01-01 00:00:00") {
        return "-";
    } else {
        return moment(input).format("YYYY-MM-DD HH:mm:ss");
    }
}

export function formatNumber(input, decimal = 4) {
    if (input) {
        return Number(input).toLocaleString("en-US", {
            maximumFractionDigits: decimal,
            minimumFractionDigits: decimal,
        });
    } else {
        return 0;
    }
}

export function trimFormInput(form, event) {
    const inputId = event.target.id;
    const inputVal = event.target.value;
    const trimString = String(inputVal).trim();

    form.setFieldsValue({
        [inputId]: trimString,
    });

    return;
}

export function capitalizeFormInput(form, event) {
    const inputId = event.target.id;
    const inputVal = event.target.value;
    const trimString = String(inputVal).trim();
    const capitalizeString = String(trimString).toUpperCase();

    form.setFieldsValue({
        [inputId]: capitalizeString,
    });

    return;
}
