import React, { useContext } from "react";
import { AuthContext } from "../../../../../context/AuthContext";
import useRouletteBetPanel from "../../../hook/useRouletteBetPanel";
import { useChips } from "../../../../../hook/useChips";
import "./VBetPanel.scss";
import {
    data,
    subData,
    handleGetMainBetItemContent,
    handleHoverLeave,
    handleHoverItem,
    handleGetVSubBetItemContent,
    handleOvalHoverItem,
    handleOvalHoverLeave,
    vSubBetItem,
    hSubBetItem,
} from "../RouletteBetConst";
import { useBetPanel } from "../../../../../hook/useBetPanel";
import { message } from "antd";

function VBetPanel({ chipArr, tableDetail, dealerSignal, setTotalBet, setLabelBet, type = "normal", betContainerClass }) {
    const { balance, isVertical, windowWidth } = useContext(AuthContext);

    const { handlePlaceBet } = useBetPanel();
    const { handleRoulettePlaceBet } = useRouletteBetPanel();
    const { handlePlaceChipFixed, handleGetSelectedChip } = useChips();

    async function handleBet(key, e) {
        console.log(key);
        if (tableDetail && dealerSignal && dealerSignal?.action === "new-game" && betContainerClass) {
            try {
                const result = await handlePlaceBet(key, tableDetail, dealerSignal);
                setTotalBet(result?.totalBet);
                handlePlaceChipFixed(
                    chipArr,
                    e.target.parentElement.getAttribute("data-id"),
                    { image: result?.image, value: result?.curBetCode },
                    result?.uniqueID,
                    result?.roundUniqueId
                );
                setLabelBet("totalBet");
            } catch (error) {
                message.info({ content: error, key: error });
            }
        }
    }

    async function handleOvalBet(item, e) {
        if (tableDetail && dealerSignal && dealerSignal?.action === "new-game" && betContainerClass) {
            const key = item.code;

            let selected_chip = await handleGetSelectedChip().catch((error) => {
                return reject(error);
            });

            const codeList = key.split(",");
            let tempTotal = 0;

            for (const element of codeList) {
                if (element.split("*").length > 1) {
                    tempTotal += selected_chip.value * 2;
                } else {
                    tempTotal += selected_chip.value;
                }
            }

            if (tempTotal > balance) {
                message.info("Insufficient Balance");
            } else {
                let temp = 0;
                let result;

                for (const element of codeList) {
                    // console.log(element);
                    try {
                        for (let i = 0; i < element.split("*").length; i++) {
                            result = await handleRoulettePlaceBet(element.split("*")[0], tableDetail, dealerSignal);
                            temp += result?.curValue;

                            handlePlaceChipFixed(
                                chipArr,
                                element.split("*")[0],
                                {
                                    image: result?.image,
                                    value: result?.curBetCode,
                                },
                                result?.uniqueID,
                                result?.roundUniqueId
                            );
                        }
                        setTotalBet(result?.totalBet);
                        if (item.color !== "transparent") {
                            handlePlaceChipFixed(
                                chipArr,
                                element + "|o",
                                {
                                    image: result?.image,
                                    value: result?.curBetCode,
                                },
                                result?.uniqueID + "|o",
                                result?.roundUniqueId
                            );
                        }

                        setLabelBet("totalBet");
                    } catch (error) {
                        message.info({ content: error, key: error });
                    }
                }

                if (item.color === "transparent") {
                    const lastTotal =
                        e.target.parentElement.getElementsByClassName("chip-placed")[
                            e.target.parentElement.getElementsByClassName("chip-placed").length - 1
                        ]?.children[0]?.children[0]?.children[1]?.innerHTML;
                    handlePlaceChipFixed(
                        chipArr,
                        e.target.parentElement.getAttribute("data-id"),
                        {
                            image: result?.image,
                            value: lastTotal ? Number(lastTotal) + temp : temp,
                        },
                        result?.uniqueID,
                        result?.roundUniqueId
                    );
                }
            }
        }
    }

    const VNoramlBetPanel = () => {
        if (isVertical && windowWidth < 500) {
            return (
                <div className="normal-bet-panel-container">
                    <div className="normal-bet-panel">
                        <div className="sub-bet-item">
                            <div className={`sub-bet-item-0`}>{handleGetVSubBetItemContent(-1)}</div>
                            {subData.map((item, index) => (
                                <div key={index} data-id={item.code} className={`sub-bet-item-wrapper sub-bet-item-${index + 1}`}>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            width: "100%",
                                            transform: index === 4 && index === 6 ? "" : "rotate(90deg)",
                                        }}
                                    >
                                        {handleGetVSubBetItemContent(index)}
                                    </div>
                                    <div
                                        style={{ position: "absolute", width: "100%", height: "100%" }}
                                        onMouseLeave={handleHoverLeave}
                                        onMouseOver={handleHoverItem}
                                        onClick={(e) => handleBet(item.code, e)}
                                    />
                                </div>
                            ))}
                            <div className={`sub-bet-item-10`}>{handleGetVSubBetItemContent(10)}</div>
                        </div>
                        <div className="main-bet-item">
                            {data.map((item, index) => (
                                <div key={index} className={`main-bet-item-wrapper main-bet-item-${index}`}>
                                    <div data-id={item.code} className={`main-bet-item-child main-item-0 ${item.color}`}>
                                        <div>{handleGetMainBetItemContent(index)}</div>
                                        <div
                                            style={{ position: "absolute", width: "100%", height: "100%" }}
                                            onMouseLeave={handleHoverLeave}
                                            onMouseOver={handleHoverItem}
                                            onClick={(e) => handleBet(item.code, e)}
                                        />
                                    </div>
                                    {index > 0 &&
                                        item.children.map((data, index2) => (
                                            <div
                                                key={index2}
                                                data-id={data.code}
                                                style={{ display: !data.display && "none" }}
                                                className={`main-bet-item-child main-item-${data.key}`}
                                            >
                                                <div
                                                    style={{ position: "absolute", width: "100%", height: "100%" }}
                                                    onMouseLeave={handleHoverLeave}
                                                    onMouseOver={handleHoverItem}
                                                    onClick={(e) => handleBet(data.code, e)}
                                                />
                                            </div>
                                        ))}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="h-roulette-bet-panel">
                    <div className="main-bet-panel-container">
                        {data.map((item, index1) => (
                            <div key={index1} className={`main-bet-panel-item main-panel-item-${index1}`}>
                                <div data-id={item.code} className={`main-bet-item main-item-0 ${item.color}`}>
                                    <div>{handleGetMainBetItemContent(index1)}</div>
                                    <div
                                        style={{ position: "absolute", width: "100%", height: "100%" }}
                                        onMouseLeave={handleHoverLeave}
                                        onMouseOver={handleHoverItem}
                                        onClick={(e) => handleBet(item.code, e)}
                                    />
                                </div>
                                {index1 > 0 &&
                                    item.children.map((data, index2) => (
                                        <div
                                            key={index2}
                                            data-id={data.code}
                                            style={{ display: !data.display && "none" }}
                                            className={`main-bet-item main-item-${index2 + 1}`}
                                        >
                                            <div
                                                style={{ position: "absolute", width: "100%", height: "100%" }}
                                                onMouseLeave={handleHoverLeave}
                                                onMouseOver={handleHoverItem}
                                                onClick={(e) => handleBet(data.code, e)}
                                            />
                                        </div>
                                    ))}
                            </div>
                        ))}
                    </div>
                    <div className="sub-bet-panel-container">
                        {subData.map((item, index) => (
                            <div key={index} data-id={item.code} className={`sub-bet-panel-item sub-panel-item-${index}`}>
                                {handleGetVSubBetItemContent(index)}
                                <div
                                    style={{ position: "absolute", width: "100%", height: "100%" }}
                                    onMouseLeave={handleHoverLeave}
                                    onMouseOver={handleHoverItem}
                                    onClick={(e) => handleBet(item.code, e)}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            );
        }
    };

    const VOvalBetPanel = () => {
        if (isVertical && windowWidth < 500) {
            return (
                <div className="oval-bet-panel-container">
                    <div className="oval-bet-panel">
                        <div className="oval-bet-item-wrapper">
                            {vSubBetItem.map((item, index) => (
                                <div key={index} data-id={item.code + "|o"} className={`oval-bet-item oval-bet-item-${index + 1} ${item.color}`}>
                                    <div>{item.key}</div>
                                    <div
                                        style={{ position: "absolute", width: "100%", height: "100%" }}
                                        onMouseLeave={() => handleOvalHoverLeave(item)}
                                        onMouseOver={() => handleOvalHoverItem(item)}
                                        onClick={(e) => handleOvalBet(item, e)}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="h-roulette-oval-bet-panel">
                    <div className="roulette-oval-main-bet-panel-container">
                        {hSubBetItem.map((item, index) => (
                            <div key={index} data-id={item.code + "|o"} className={`main-bet-item ${item.color}`}>
                                <div>{item.key}</div>
                                <div
                                    style={{ position: "absolute", width: "100%", height: "100%" }}
                                    onMouseLeave={() => handleOvalHoverLeave(item)}
                                    onMouseOver={() => handleOvalHoverItem(item)}
                                    onClick={(e) => handleOvalBet(item, e)}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            );
        }
    };

    return <div className="v-roulette-bet-panel-container">{type === "normal" ? VNoramlBetPanel() : VOvalBetPanel()}</div>;
    // return <VNoramlBetPanel />;
}

export default VBetPanel;
